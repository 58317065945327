var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.implementation)?_c('header',{attrs:{"id":"projectNavigation"}},[_c('nav',[_c('router-link',{staticClass:"logo",attrs:{"to":{ name: 'implementations-overview' },"exact":""}},[_c('logoIcon')],1),_c('router-link',{attrs:{"to":{
        name: 'implementation-dashboard',
        params: { implementation_id: _vm.implementation.id },
      },"exact":""}},[_vm._v(" Dashboard ")]),_c('router-link',{attrs:{"to":{
        name: 'implementation-team',
        params: { implementation_id: _vm.implementation.id },
      }}},[_vm._v(" Team ")]),_c('router-link',{attrs:{"to":{
        name: 'implementation-scope',
        params: { implementation_id: _vm.implementation.id },
      }}},[_vm._v(" Scope ")]),_c('router-link',{attrs:{"to":{
        name: 'implementation-questions',
        params: { implementation_id: _vm.implementation.id },
      }}},[_vm._v(" Implementations ")]),_c('router-link',{attrs:{"to":{
        name: 'implementation-document',
        params: { implementation_id: _vm.implementation.id },
      }}},[_vm._v(" Document ")])],1),_c('div',{staticClass:"profile-menu"},[_c('div',{staticClass:"profile-menu-item"},[_c('EntityPicker',{attrs:{"implementation":_vm.implementation}})],1),_c('div',{staticClass:"profile-menu-item"},[_c('ProfileMenu',{attrs:{"notifications":_vm.implementation.notifications,"notificationsPlaceholder":'You have 0 notifications. Notifications include project mentions and actions that are relevant for you in your projects.'},on:{"closeNotifications":_vm.readNotifications}})],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }