









import { Route } from 'vue-router'
import { stateModule } from '@/store'
import { Component, Vue } from 'vue-property-decorator'

import Loader from '@/components/loaders/Loader.vue'
import Navigation from './partials/Navigation.vue'

import { ImplementationResource } from '@/models/implementations/ImplementationResource'
import { ImplementationsService } from '@/services/implementations'

const service = new ImplementationsService()

@Component({
  components: {
    Loader,
    Navigation,
  },
})
export default class ImplementationDetail extends Vue {
  private implementation: ImplementationResource | null = null

  private async beforeRouteEnter(to: Route, _: Route, next: any): Promise<void> {
    stateModule.setLoading(true)
    try {
      const { data } = await service.getById(+to.params.implementation_id)
      // If user can see all entities hes creator and we will link the user to the dashboard
      if (data.canPerformAction('can_see_all_entities')) {
        next((vm: any) => {
          vm.implementation = data
        })
        return
      }
      // If not the user probably can only see entities and we will link the user to the first entity the user belongs
      const firstEntityUserBelongs = data.entities.find(
        (entity) => entity.belongs_to,
      )
      if (firstEntityUserBelongs) {
        next({
          name: 'implementation-entity-implementations',
          params: {
            implementation_id: `${data.id}`,
            entity_id: `${firstEntityUserBelongs.id}`,
          },
        })
        return
      }

      next({ name: 'implementations-overview' })
    } catch (e) {
      next({ name: 'implementations-overview' })
    } finally {
      stateModule.setLoading(false)
    }
  }

  private get loading(): boolean {
    return stateModule.loading
  }
}
