
































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ImplementationResource } from '@/models/implementations/ImplementationResource'

import EntityPicker from './EntityPicker.vue'
import ProfileMenu from '@/components/ProfileMenu.vue'

@Component({
  components: {
    EntityPicker,
    ProfileMenu,
  },
})
export default class ProjectNavigation extends Vue {
  @Prop()
  private readonly implementation!: ImplementationResource

  private menuOpen: boolean = false


  private toggleMenu(): void {
    this.menuOpen = !this.menuOpen
  }

  private closeMenu(): void {
    this.menuOpen = false
  }

  private readNotifications(): void {
    // this.implementation.notifications.readAllNotifications()
  }

}
